import React from 'react'
import './style.scss';

export function TechnologyIndex() {
    return(
        <div className="container">
            <div className="space-105"></div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="technology-v3-left">
                        <div className="ot-heading">
                            <span>Database</span>
                            <h2 className="main-heading mt-3">Improve and Innovate with the Tech Trends</h2>
                        </div>
                        <div className="space-5"></div>
                        <p>We have worked with Relational as well as NoSQL databases for faster data processing in our solutions.</p>
                        <div className="space-10"></div>
                        <div className="et-progress">
                            <div className="ot-progress">
                                <div className="overflow">
                                    <a href="#database-sql"><span className="pname f-left">MySQL</span></a>
                                </div>
                                <div className="iprogress">
                                    <div data-percent="70%" className="progress-bar"></div>
                                </div>
                            </div>
                        </div>
                        <div className="et-progress">
                            <div className="ot-progress">
                                <div className="overflow">
                                    <a href="#database-sql-server"><span className="pname f-left">SQL Server</span></a>
                                </div>
                                <div className="iprogress">
                                    <div data-percent="70%" className="progress-bar"></div>
                                </div>
                            </div>
                        </div>
                        <div className="et-progress">
                            <div className="ot-progress">
                                <div className="overflow">
                                    <a href="#database-postgresql"><span className="pname f-left">PostgreSQL</span></a>
                                </div>
                                <div className="iprogress">
                                    <div data-percent="70%" className="progress-bar"></div>
                                </div>
                            </div>
                        </div>
                        <div className="et-progress">
                            <div className="ot-progress">
                                <div className="overflow">
                                    <a href="#database-mongodb"><span className="pname f-left">MongoDB</span></a>
                                </div>
                                <div className="iprogress">
                                    <div data-percent="70%" className="progress-bar"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <img src="/assets/img/image2-about.png" alt=""/>
                </div>
            </div>
            <div className="space-105"></div>
        </div>
    )
}

export function Database() {
    return(
        <section className="service-page about-counter">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12 mb-12 mb-sm-0">
                        <div className="ot-heading text-center mb-0">
                            <span className="mb-3"> Database </span>
                        </div>
                    </div>
                </div>
                <div className="space-55"></div>
                <div className="row">
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                        <div className="serv-box-2 s2">
                            <div className="icon-main">
                                <span className="flaticon-tablet"></span>
                            </div>
                            <div className="content-box" id="database-sql">
                                <h4>MySQL</h4>
                                <div>MySQL is a relational database management system based on SQL(Structured Query Language). To add, access and process any kind of data stored in a computer database, we need a database management system(DBMS) such as MySQL Server. MySQL is used for a wide range of purposes, including data warehousing, e-commerce, logging application etc.</div>
                                {/* <a href="" className="btn-details">
                                    <i className="flaticon-right-arrow-1"></i> LEARN MORE
                                </a> */}    
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                        <div className="serv-box-2 s2">
                            <div className="icon-main">
                                <span className="flaticon-tablet"></span>
                            </div>
                            <div className="content-box" id="database-postgresql">
                                <h4>PostgreSQL</h4>
                                <div>PostgreSQL, also known as Postgres, is a free and open-source relational database management system emphasizing extensibility and SQL compliance. PostgreSQL can store structured and unstructured data in a single product.</div>
                                {/* <a href="" className="btn-details">
                                    <i className="flaticon-right-arrow-1"></i> LEARN MORE
                                </a> */}    
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                        <div className="serv-box-2 s2">
                            <div className="icon-main">
                                <span className="flaticon-tablet"></span>
                            </div>
                            <div className="content-box" id="database-mongodb">
                                <h4>MongoDB</h4>
                                <div>A document-oriented database which stores data in JSON-like documents with dynamic schema. It means you can store your records without worrying about the data structure such as the number of fields or types of fields to store values. MongoDB documents are similar to JSON objects. The motivation of the MongoDB language is to implement data store that provides high performance, high availability, and automatic scaling.</div>
                                {/* <a href="" className="btn-details">
                                    <i className="flaticon-right-arrow-1"></i> LEARN MORE
                                </a> */}    
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                        <div className="serv-box-2 s2">
                            <div className="icon-main">
                                <span className="flaticon-tablet"></span>
                            </div>
                            <div className="content-box" id="database-sql-server">
                                <h4>SQL SERVER</h4>
                                <div>To add, access and process any kind of data stored in a computer database, we need a database management system(DBMS) such as MySQL Server.</div>
                                {/* <a href="" className="btn-details">
                                    <i className="flaticon-right-arrow-1"></i> LEARN MORE
                                </a> */}    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export function WebDevelopment( props ) {
    const { title, description, list, side='left' } = props;
    return(
        <div className="container" key={title}>
            <div className="space-105"></div>
                <div className="row">
                { side ==='left'? <div className="col-lg-6">
                    <img src="/assets/img/image2-about.png" alt=""/>
                </div> : null}
                <div className="col-lg-6">
                    <div className="technology-v3-left">
                        <div className="ot-heading">
                            <span>{title}</span>
                        </div>
                        <div className="space-5"></div>
                        <p>{description}</p>
                        <div className="space-10"></div>
                        {
                            list ? list.map( (data, i) => <div className="et-progress" key={i}>
                            <div className="ot-progress" key={data.title}>
                                <div className="overflow">
                                    <span className="pname f-left">{data.title}</span>
                                </div>
                                <div className="iprogress">
                                    <div data-percent="70%" className="progress-bar"></div>
                                </div>
                            </div>
                        </div>) : null
                        }
                    </div>
                </div> 
                { side === 'right'? <div className="col-lg-6">
                    <img src="/assets/img/image2-about.png" alt=""/>
                </div> : null}
            </div>
            <div className="space-105"></div>
        </div>
    )
}

export function Magento() {
    return(
        <>
            <section className="over-hidden">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-12 col-sm-12 align-self-center">
                            <div className="ot-heading">
                                <h1> Magento Development</h1>
                                <h3 className="main-heading">Why should you choose Magento for your    online store development?</h3>
                            </div>
                            <p className="mb-15">We are aware of the fact that eCommerce is having fast paced growth that changes rapidly. In such a scenario, you have to wisely choose a platform that is capable of responding appropriately.</p>
                            <p className="mb-15">The answer itself is the Magento. Whether you have a small or a large business, Magento is scalable as per your need. It adapts to all the requirements because of its flexibility.</p>
                            <p className="mb-15">Moreover, Magento development supports mCommerce across multiple web browsers and devices.</p>
                            <p>Being a Top-rated Magento Development Agency, reputed Solutions Partner and Premier Extension builder. We Provide numerous custom designed features that guarantee diverse functionality and a smooth customer experience. Build your ecommerce store and make it grow with an award winning Mangento Development company.</p>
                        </div>
                        <div className="offset-lg-1 col-lg-5 col-md-12 col-sm-12 align-self-center">
                            <div className="about-right">
                                <div className="home-about-video d-flex justify-content-center">
                                    <div className="video-btn align-self-center"></div>
                                </div>
                                <div className="home-about-btn">
                                    <div className="ot-button">
                                        <button className="btn-details bg-primary" tabIndex="0">
                                            <i className="flaticon-right-arrow-1"></i> LEARN MORE
                                        </button>
                                        <div className="space-15">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default function Technology() {
    return (
        <section className="technology-v1">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="ot-heading text-center text-white">
                            <h1 className="text-white"> TECHNOLOGY INDEX</h1>
                            <h3 className="main-heading text-white">We Deliver Solution with <br/>the Goal of Trusting Relationships</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                        <span target="_blank" className="tech-box text-center">
                            <div className="icon-main">
                                <span className="flaticon-code-1"></span>
                            </div>
                            <h5>JavaScript</h5><br/>
                            <div><span className="main-heading">Famous Apps : </span>Netflix, Candy Crush, Facebook</div><br/>
                            <div><span className="main-heading">Frameworks : </span>Express.js, React, Vue, Angular</div>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                        <span target="_blank" className="tech-box text-center">
                            <div className="icon-main">
                                <span className="flaticon-code-1"></span>
                            </div>
                            <h5>Python</h5><br/>
                            <div><span className="main-heading">Famous Apps : </span>Instagram, Google, Spotify</div><br/>
                            <div><span className="main-heading">Frameworks : </span>Django, Flask, CherryPy</div>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                        <span target="_blank" className="tech-box text-center">
                            <div className="icon-main">
                                <span className="flaticon-code-1"></span>
                            </div>
                            <h5>Ruby</h5><br/>
                            <div><span className="main-heading">Famous Apps : </span>GitHub, Zendesk, Basecamp</div><br/>
                            <div><span className="main-heading">Frameworks : </span>Ruby on Rails, Sinatra, Padrino</div>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                        <span target="_blank" className="tech-box text-center">
                            <div className="icon-main">
                                <span className="flaticon-code-1"></span>
                            </div>
                            <h5>PHP</h5><br/>
                            <div><span className="main-heading">Famous Apps : </span>Yahoo, WordPress, Wikipedia</div><br/>
                            <div><span className="main-heading">Frameworks : </span>Laravel, Cake PHP, Symfony</div>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                        <span target="_blank" className="tech-box text-center">
                            <div className="icon-main">
                                <span className="flaticon-code-1"></span>
                            </div>
                            <h5>Java</h5><br/>
                            <div><span className="main-heading">Famous Apps : </span>NASA WorldWind, Hadoop, Jenkins</div><br/>
                            <div><span className="main-heading">Frameworks : </span>Blade, Dropwizard, GWT</div>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                        <span target="_blank" className="tech-box text-center">
                            <div className="icon-main">
                                <span className="flaticon-code-1"></span>
                            </div>
                            <h5>C#</h5><br/>
                            <div><span className="main-heading">Famous Apps : </span>Visual Studio, Windows Installer, NMath</div><br/>
                            <div><span className="main-heading">Frameworks : </span>.NET, Xamarin, Unity</div>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                        <span target="_blank" className="tech-box text-center">
                            <div className="icon-main">
                                <span className="flaticon-code-1"></span>
                            </div>
                            <h5>Perl</h5><br/>
                            <div><span className="main-heading">Famous Apps : </span>IMDB, Amazon, BBC</div><br/>
                            <div><span className="main-heading">Frameworks : </span>Catalyst, Dancer, TAP</div>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                        <span target="_blank" className="tech-box text-center">
                            <div className="icon-main">
                                <span className="flaticon-code-1"></span>
                            </div>
                            <h5>C++</h5><br/>
                            <div><span className="main-heading">Famous Apps : </span>Adobe Photoshop, Microsoft Office, Mozilla Firefox</div><br/>
                            <div><span className="main-heading">Frameworks : </span>Apache, APR, ASL</div>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                        <span target="_blank" className="tech-box text-center">
                            <div className="icon-main">
                                <span className="flaticon-code-1"></span>
                            </div>
                            <h5>Kotlin</h5><br/>
                            <div><span className="main-heading">Famous Apps : </span>Square, Trello, Evernote</div><br/>
                            <div><span className="main-heading">Frameworks : </span>Javalin, KTor, Spark</div>
                        </span>
                    </div>
                </div>
            </div>
        </section>
    )
}
