import React from 'react';
import './style.scss';
import { Cards } from '../../components/section/card'

function Home(props) {
    return (
        <>
            <section className="hero-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 align-self-center">
                            <h1 className="hero-heading">We offer IT solutions for growing your business</h1>
                            <p className="hero-para">We believe that the technology has significant if its use can benefit the common citizens that’s why we are committed to the dissolution of technological monopoly. We have the finest IT squad which deals in Web Development, App development, SEO, E-commerce development, and in all other IT solutions.</p>
                        </div>
                        <div className="col-lg-5 col-md-12 align-self-center offset-lg-1">
                            <img src="/assets/img/programmers.jpg" alt="We offer IT solutions for growing your business" className="rounded-shadow"/>
                        </div>
                    </div>
                </div>
            </section>
            <Cards />
        </>

    )
}

export default Home
