import React from 'react';
// import { Router, Route, Switch, withRouter } from 'react-router-dom'
import {
	BrowserRouter as Router,
	Switch,
	Route,
  } from "react-router-dom";
import './App.scss';
import { Footer } from './components/footer';
import { Header } from './components/header'
import { PageHeader } from './components/page-header';

import {Home} from './pages/homepage';
import {AboutUs} from './pages/about-us';
import {Services} from './pages/services';
import {Contact} from './pages/contact';
import { WebDevelopment } from './pages/web-development';
import { APIDevelopment } from './pages/api-development';
import ScrollToTop from './utils/scroll-top'
import Technologies from './pages/technology/technology';
import { UXDesign } from './pages/design';
import {TestingPage} from './pages/testing';

const App = () => {
  return (
	  <Router>
		<ScrollToTop />
		<div className="mat-typography">
			<Header />
			<PageHeader />
			<Switch>
				<Route exact path="/">
					<Home />
				</Route>
				<Route path="/about-us">
					<AboutUs />
				</Route>
				<Route path="/services">
					<Services />
				</Route>
				<Route path="/testing">
					<TestingPage />
				</Route>
				<Route path="/technology">
					<Technologies />
				</Route>
				<Route path="/contact">
					<Contact />
				</Route>
				<Route path="/web-development">
					<WebDevelopment />
				</Route>
				<Route path="/api-development">
					<APIDevelopment />
				</Route>
				<Route path="/design">
					<UXDesign />
				</Route>
			</Switch>
			<Footer />
		</div>
	  </Router>
  );
};

export default App;
