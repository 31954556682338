import React from 'react'
import { useLocation } from "react-router-dom";
import './style.scss';

export default function PageHeader( props ) {
    const { pathname } = useLocation();
    
    if( '' === pathname || '/' === pathname ) {
        return null;
    }
    const tempTitle = getTitleBySlug(pathname);
    const { title = tempTitle } = props;
    return (
        <div className="page-header flex-middle">
            <div className="container">
                <div className="inner flex-middle">
                    <h1 className="page-title text-center">{title}</h1>
                </div>
            </div>
        </div>
    )
}

function getTitleBySlug(slug) {
    
    var words = slug.replace('/', '');
    words     = words.split('-');
  
    for (var i = 0; i < words.length; i++) {
      var word = words[i];
      words[i] = word.charAt(0).toUpperCase() + word.slice(1);
    }
  
    return words.join(' ');
  }