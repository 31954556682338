import React from 'react'

function TestingPage() {
    return (
        <>
            <section className="service-page about-counter section-T60">
                <div className="container">
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8 col-sm-12 mb-12 mb-sm-0">
                            <div className="text-center mb-0">
                                <h1>Software  Manual Testing</h1>
                                <p className="para-lg">Softogrid Solutions is the best Software Testing or SQT training institute in Noida, Delhi, Gurgaon and Ghaziabad . You will get practical training on Software Testing or SQT by our Software Testing or SQT expert who have 5+ year industrial experience. Instructors at Softogrid Solutions provides lessons that are taught using REAL-LIFE SCENARIOS for improved learning and pay more attention to the individual needs of a student.  Softogrid Solutions offer 100% placement assistance on Software Testing or SQT training</p>
                            </div>
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                    <div className="space-55"></div>
                    <div className="col-md-12 col-sm-12 mb-12 mb-sm-0"><div className="ot-heading text-center mb-0"><span className="mb-3"> Software testing services for you </span></div></div>
                    <div className="space-55"></div>

                    <div className="row">
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                            <div className="serv-box-2 s2">
                                <div className="content-box">
                                    <h4>Configuration and Installation Test</h4>
                                    <div>We ensure that an application is configured to perfection so that it performs as expected after the installation is complete. Installation & configuration tests the performance of a web or mobile app on different operating systems, devices, supported drivers, etc.</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                            <div className="serv-box-2 s2">
                                <div className="content-box" id="database-postgresql">
                                    <h4>Degree of Localization</h4>
                                    <div>Softogrid Solutions understands the importance of mobile app localization and incorporates the artistic, linguistic and technical customizations to blend with the culture of the target audience of any location. This helps the software to gain popularity in the long-term!</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                            <div className="serv-box-2 s2">
                                <div className="icon-main">
                                    <span className="flaticon-tablet"></span>
                                </div>
                                <div className="content-box" id="database-mongodb">
                                    <h4>Performance Testing</h4>
                                    <div>Performance is very crucial in any mobile app or web app. The application’s efficiency will dictate the user’s stay. The speed and adaptability check is conducted efficiently by the quality inspectors and makes sure that the execution attributes match the industry standards.</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                            <div className="serv-box-2 s2">
                                <div className="icon-main">
                                    <span className="flaticon-tablet"></span>
                                </div>
                                <div className="content-box" id="database-sql-server">
                                    <h4>System & Integration Testing</h4>
                                    <div>We quality assurance team supervises an effective system & integration testing of software on a fully integrated system & adequately assesses the system’s conformity with the specified set of requirements. Testing of the smallest testable units is performed in numerous stages.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TestingPage
