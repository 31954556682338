import React from 'react'
import SVGIcon from '../../components/svg-icons'

function WebDevelopment() {
    return (
        <>
            <section className="service-web" key="service-web">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 text-center mb-5 mb-lg-0 align-self-center">
                            <img src="/assets/img/web-development.jpg" alt="" className="img-fluid" />
                        </div>
                        <div className="col-lg-6">
                            <div className="service-process">
                                <div className="ot-heading"><span className="mb-3"> About Service</span>
                                    <h2 className="main-heading">We Provide Best <br /><br />Web Development</h2>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="block-card">
                                            <div className="svg-block">
                                                <SVGIcon icon="java"/>
                                                <span>Java</span>
                                            </div>
                                            <div className="svg-block">
                                                <SVGIcon icon="php"/>
                                                <span>PHP</span>
                                            </div>
                                            <div className="svg-block">
                                                <SVGIcon icon="js"/>
                                                <span>Java Script</span>
                                            </div>
                                            <div className="svg-block">
                                                <SVGIcon icon="react"/>
                                                <span>React.js</span>
                                            </div>
                                            <div className="svg-block">
                                                <SVGIcon icon="node"/>
                                                <span>Node.js</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="service-type section--misc section-T60" key="service-type">
                <div className="container">
                    <div className="brick-wrap">
                        <div className="svg-block">
                            <SVGIcon icon="java"/>
                            <h2>JAVA</h2>
                        </div>
                        <p><strong>Java application development services</strong> imply the engineering of powerful web   and mobile applications, web services, and embedded software solutions able to run on any platform with the Java language and frameworks</p>
                        <p><strong>Softogrid Solutions</strong> is a <strong>Java application development company</strong> that has  already accumulated <strong>more than 8 years of experience in Java development</strong>. We’re committed to building sustainable and high-quality Java solutions using the latest and most trusted techs and architecture patterns.</p>
                    </div>
                    
                    <div className="space-60"></div>

                    <div className="brick-wrap">
                        <div className="svg-block">
                            <SVGIcon icon="php"/>
                            <h2>PHP</h2>
                        </div>
                        <p><strong>PHP</strong> is an open-source programming language powering up 80% of the web. It is the language of the White House websites, Facebook, Slack, Wikipedia, Flickr, and the basis for the majority of present-day web services, B2C and B2B applications, content management systems, and ecommerce solutions</p>
                        <p>PHP is a mature, all-purpose scripting language designed to be used on the Internet for creating interactive components within websites or to build entire interactive websites, like Facebook, Craigslist or Twitter. Examples of the kinds of systems that we can create using PHP web application development include: CMS, Custom online database systems, Membership & Subscription-based websites, Message boards / discussion forums, Other community and social-based websites, Blogs, Custom e-commerce systems, Registration systems, Analytics programs, E-learning applications & systems, Fulfillment applications, Survey systems, Member-only portals, Back office applications, Mobile development (tablet, smartphone, etc.), Much more!</p>
                    </div>

                    <div className="space-60"></div>

                    <div className="brick-wrap">
                        <div className="svg-block">
                            <SVGIcon icon="js"/>
                            <h2>Java Script</h2>
                        </div>
                        <p><strong>JavaScript</strong> is a <strong>client-side development language</strong> that helps create dynamic website content. Powered by Node.js, JavaScript serves as a <strong>fast server-side language</strong> for the entire application to be coded in JavaScript.</p>
                        <p>Softogrid Solutions’s  developers leverage JavaScript tobuild <strong>richly interactive web and    mobile applications with accelerated loading time.</strong></p>
                        <p>With two decades of improvement, JavaScript has become one of the most popular programming languages of all time. The journey started in 1995 when Brendan Eich created JavaScript in just 10 days. From there, it has seen multiple revisions, drafts, and growth in the form of frameworks, API’s, modules, etc. Today, we will go forward and list the top JavaScript blogs from the internet so that you can enjoy the lastest development in the field of JavaScript.</p>
                        <p>According to RedMonk programming language rankings and GitHut.info, JavaScript is leading the pack in the terms of repositories and the most discussed programming language on StackOverFlow. The numbers itself speaks about the future of JavaScript as it has grown beyond the initial capabilities of simple DOM manipulations.</p>
                        <p><a href="https://www.liveedu.tv/livestreams/javascript/" target="_blank" rel="noopener noreferrer">Learning JavaScript</a>, on the other hand, can be a tricky proposition. New libraries, features, API’s or Style Guide, pop up almost every day. The speed of iteration is beyond imagination, and that is why reading leading JavaScript blogs are the best approach to keep up with new changes.</p>
                    </div>

                    <div className="space-60"></div>

                    <div className="brick-wrap">
                        <div className="svg-block">
                            <SVGIcon icon="react"/>
                            <h2>React.js</h2>
                        </div>
                        <p><strong>React.js consulting</strong> is aimed at solving tech challenges of React.js development at any stage of the app development project life cycle, from launch to deployment, and improvement of front-end performance of a functioning application</p>
                        <p>Softogrid Solutions offers React.js consulting services with access to the latest React.js knowledge and skills. Our React.js consultants help create frictionless interaction with applications for users, boost application maintainability and code quality.</p>
                        <p>React is a library for building composable user interfaces. It encourages the creation of reusable UI components, which present data that changes over time. Lots of people use React as the V in MVC. React abstracts away the DOM from you, offering a simpler programming model and better performance. React can also render on the server using Node, and it can power native apps using React Native. React implements one-way reactive data flow, which reduces the boilerplate and is easier to reason about than traditional data binding.</p>
                    </div>

                    <div className="space-60"></div>

                    <div className="brick-wrap">
                        <div className="svg-block">
                            <SVGIcon icon="node"/>
                            <h2>Node.js</h2>
                        </div>
                        <p>Node.js consulting helps smoothly launch the <a href="https://www.scnsoft.com/services/nodejs" target="_blank" rel="noopener noreferrer">development of future-proof Node.js applications</a> and streamline or recover ongoing development. With Node.js consulting from Softogrid Solutions you get high-performing Node.js applications delivered and scaled <strong>~x1.5-2 faster</strong> and upgrade Node.js competencies of your in-house team</p>
                        <p>Node.js runs the V8 JavaScript engine, the core of Google Chrome, outside of the browser. This allows Node.js to be very performance.</p>
                        <p>A Node.js app runs in a single process, without creating a new thread for every request. Node.js provides a set of asynchronous I/O primitives in its standard library that prevent JavaScript code from blocking and generally, libraries in Node.js are written using non-blocking paradigms, making blocking behavior the exception rather than the norm.</p>
                        <p>When Node.js performs an I/O operation, like reading from the network, accessing a database or the file system, instead of blocking the thread and wasting CPU cycles waiting, Node.js will resume the operations when the response comes back.</p>
                        <p>This allows Node.js to handle thousands of concurrent connections with a single server without introducing the burden of managing thread concurrency, which could be a significant source of bugs.</p>
                        <p>Node.js has a unique advantage because millions of frontend developers that write JavaScript for the browser are now able to write the server-side code in addition to the client-side code without the need to learn a completely different language.</p>
                        <p>In Node.js the new ECMA Script standards can be used without problems, as you don't have to wait for all your users to update their browsers - you are in charge of deciding which ECMA Script version to use by changing the Node.js version, and you can also enable specific experimental features by running Node.js with flags.</p>
                    </div>
                </div>


            </section>
        </>
    )
}

export default WebDevelopment
