import React from 'react'
import PageHeader from '../../components/page-header/page-header'

function AboutUs() {
    return (
        <>
                        <section className="about-offer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 align-self-center mb-30 mb-lg-0 text-center">
                            <div className="ot-heading">
                                <span> What We Offer</span>
                                <h2 className="main-heading mt-3">We offer IT solutions for growing your business</h2>
                            </div>
                            <div className="space-5"></div>
                            <p>Having talented and specialized employees working for you can become a catalyst for enhanced operational efficiency and increased productivity. Softogrid Solutions can deliver staffing solutions that will optimize and streamline your recruitment process offering best in quality, speed, and often reducing overall recruitment costs..</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about-v5">
                <div className="overlay overlay-image-about5"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 align-self-center">
                            <div className="pabout-left">
                                <img src="/assets/img/programmers.jpg" alt="" className="rounded-shadow"/>
                            </div>
                        </div>
                        <div className="col-lg-7 align-self-center">
                            <div className="pabout-right">
                                <div className="ot-heading">
                                    <span> Experience. Execution. Excellence.</span>
                                    <h2 className="main-heading mt-3">What We Actually Do</h2>
                                </div>
                                <p style={{fontWeight:'300'}}>We believe that the technology has significant if its use can benefit the common citizens that’s why we are committed to the dissolution of technological monopoly. We have the finest IT squad which deals in Web Development, App development, SEO, E-commerce development, and in all other IT solutions..</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="hero-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 align-self-center">
                            <h1 className="hero-heading">Who We Are</h1>
                            <p className="hero-para">Having talented and specialized employees working for you can become a catalyst for enhanced operational efficiency and increased productivity. Softogrid Solutions can deliver staffing solutions that will optimize and streamline your recruitment process offering best in quality, speed, and often reducing overall recruitment costs.</p>
                        </div>
                        <div className="col-lg-5 col-md-12 align-self-center offset-lg-1">
                            <img src="/assets/img/about.jpg" alt="Who We Are" className="rounded-shadow"/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutUs
