import React from 'react'

function APIDevelopment() {
    return (
        <>
            <section className="service-page about-counter">
                <div className="container">
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8 col-sm-12 mb-12 mb-sm-0">
                            <div className="text-center mb-0">
                                <p className="para-lg"><strong>API development services</strong> help businesses ease cooperation and data sharing of their custom applications with each other or with third-party applications. Custom API solutions allow for simplifying and accelerating shared workflows and creating personalized customer and partner experiences.</p>
                            </div>
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                    <div className="space-55"></div>
                    <div className="row">
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                            <div className="serv-box-2 s2">
                                <div className="content-box">
                                    <h4>ROI-driven design</h4>
                                    <div>Our BAs consider API features and business processes together to ensure the max ROI of APIs we deliver</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                            <div className="serv-box-2 s2">
                                <div className="content-box" id="database-postgresql">
                                    <h4>Incremental development</h4>
                                    <div>We follow an evolutionary approach to API development and let API functionality evolve safely.</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                            <div className="serv-box-2 s2">
                                <div className="icon-main">
                                    <span className="flaticon-tablet"></span>
                                </div>
                                <div className="content-box" id="database-mongodb">
                                    <h4>High reliability and stability</h4>
                                    <div>We embed a capability for performance monitoring and management in the API solution architecture to take care of performance spikes and issues without disruptions and slowdowns in your critical business processes.</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                            <div className="serv-box-2 s2">
                                <div className="icon-main">
                                    <span className="flaticon-tablet"></span>
                                </div>
                                <div className="content-box" id="database-sql-server">
                                    <h4>Ease of use</h4>
                                    <div>For external developers to better understand, use and integrate APIs we deliver, we provide comprehensive, structured API documentation, envisage informative error messages and secure high API availability.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default APIDevelopment
