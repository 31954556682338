import React from 'react'
import { Service } from '../../components/section/service';

function Services() {
    return (
        <Service />
    )
}

export default Services
