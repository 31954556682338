import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import './style.scss';

export default function Header(props) {
    const { pathname } = useLocation();

    const headerMenuClass = path => {
        return path == pathname ? 'nav-link active' : 'nav-link';
    }
    return (
        <header className={ 'header bg-light sticky-top shadow-sm rounded' }>
            <div className="container">
                <div className="row">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <div className="octf-col logo-col">
                            <div className="site-logo" id="site-logo">
                                <Link to="/">
                                    <img src="/assets/img/logo.png" alt="softogrid"/>
                                </Link>
                            </div>
                        </div>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        
                        <div  className="collapse navbar-collapse" id="collapsibleNavbar">
                            <ul  className="menu navbar-nav mr-auto my-2 my-lg-0 octf-col">
                                <li  className="nav-item">
                                    <Link className={ headerMenuClass('/') } data-toggle="collapse" data-target=".navbar-collapse.show" style={{color:'blank'}} to="/">Home</Link>
                                </li>
                                <li  className="nav-item">
                                    <Link className={headerMenuClass( '/about-us' )} data-toggle="collapse" data-target=".navbar-collapse.show" style={{color:'blank'}} to="/about-us">About Us</Link>
                                </li>
                                <li  className="nav-item">
                                    <Link className={headerMenuClass( '/technology' )} data-toggle="collapse" data-target=".navbar-collapse.show" style={{color:'blank'}} to="/technology">Technology</Link>
                                </li>
                                <li  className="nav-item">
                                    <Link className={headerMenuClass( '/services' )} data-toggle="collapse" data-target=".navbar-collapse.show" style={{color:'blank'}} to="/services">Services</Link>
                                </li>
                                <li  className="nav-item">
                                    <Link className={headerMenuClass( '/testing' )} data-toggle="collapse" data-target=".navbar-collapse.show" style={{color:'blank'}} to="/testing">Testing</Link>
                                </li>
                                <li  className="nav-item">
                                    <Link className={headerMenuClass( '/contact' )} data-toggle="collapse" data-target=".navbar-collapse.show" style={{color:'blank'}} to="/contact">Contact</Link>
                                </li>
                            </ul>
                        </div>
                    </nav>

                </div>
            </div>
        </header>
    )
}
