import React from 'react'
import {Link} from 'react-router-dom';

function Cards(props) {
    return (
        <section className="section--card">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <div className="ot-heading">
                            <h1>Our services</h1>
                            <h3 className="main-heading">We Offer a Wide <br />Variety of IT Services</h3>
                        </div>
                    </div>
                </div>
                <div className="space-30"></div>
                <div className="row  justify-content-center">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <div className="icon-box-s2 s2 border-s1 bg bg1 text-center">
                            <div className="icon-main"><span className="flaticon-code"></span>
                            </div>
                            <div className="content-box">
                                <h3>Web Development</h3>
                                <p>We carry more than just good coding skills. Our experience makes us stand out from other web development.</p>
                                <Link className="d-inline-flex align-items-center" to="/web-development">Read More <mat-icon className="mat-icon material-icons" aria-hidden="true" data-mat-icon-type="font">{'>>'}</mat-icon></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <div className="icon-box-s2 s2 border-s1 bg bg3 text-center">
                            <div className="icon-main"><span className="flaticon-monitor"></span>
                            </div>
                            <div className="content-box">
                                <h3>API Development</h3>
                                <p>API development services help businesses ease cooperation and data sharing of their custom applications with each other or with third-party applications.</p>
                                <Link className="d-inline-flex align-items-center" to="/api-development">Read More <mat-icon className="mat-icon material-icons" aria-hidden="true" data-mat-icon-type="font">{'>>'}</mat-icon></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <div className="icon-box-s2 s2 border-s1 bg bg2 text-center">
                            <div className="icon-main"><span className="flaticon-app"></span>
                            </div>
                            <div className="content-box">
                                <h3>Ecommerce Development</h3>
                                <p>It includes guides to getting customer reviews and ratings, creating campaigns that increase brand engagement, and building lasting communities for brands. They also publish the latest <strong>ecommerce</strong> data and industry reports to help <strong>business</strong> owners stay on top of trends.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30 mb-md-0">
                        <div className="icon-box-s2 s2 border-s1 bg bg4 text-center">
                            <div className="icon-main"><span className="flaticon-browser"></span>
                            </div>
                            <div className="content-box">
                                <h3>UX/UI Design</h3>
                                <p>UX/UI Design is at the core of all solutions developed by Innovation. Our Designers work specifically based on guiding principles of Innovation as their main priority is to enhance User-Experience. We focus on creating solutions that user wants to experience with Simplicity and Scannability. So that the application can be easily understood by the users on first appearance.</p>
                                <p>We give high Priority importance to standard Design Guidelines and Call to Action based on usability of the application</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Cards
