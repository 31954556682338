import React from 'react';
import { useLocation} from 'react-router-dom';
import { ContactForm } from '../../components/contact-form';


function Contact() {
    return (
        <>
            <section className="contact-page">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="contact-left">
                                <div className="ot-heading">
                                    <h2 className="main-heading">Contact us</h2>
                                </div>
                                <div className="space-5"></div>
                                <p>Give us a call or drop by anytime, we endeavour to answer all enquiries within 24 hours on business days. We will be happy to answer your questions.</p>
                                <div className="contact-info box-style1"><i className="flaticon-world-globe"></i>
                                    <div className="info-text">
                                        <h6>Our Address:</h6>
                                        <div className="flag-icon-content">
                                            <span className="flag-icon flag-usa"></span>
                                            <p>6203 San Ignacio Avenue. San Jose, CA 95119, USA</p>
                                        </div>
                                        <div className="flag-icon-content">
                                            <span className="flag-icon flag-india"></span>
                                            <p>C25, C Block, Sector 8, Noida, Uttar Pradesh 201301</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="contact-info box-style1"><i className="flaticon-envelope"></i>
                                    <div className="info-text">
                                        <h6>Email Us:</h6>
                                        <div className="flag-icon-content">
                                            <span className="flag-icon flag-usa"></span>
                                            <p>info@softogrid.com</p>
                                        </div>
                                        <div className="flag-icon-content">
                                            <span className="flag-icon flag-india"></span>
                                            <p>info@softogrid.com</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="contact-info box-style1"><i className="flaticon-phone-1"></i>
                                    <div className="info-text">
                                        <h6>Call Us:</h6>
                                        <div className="flag-icon-content">
                                            <span className="flag-icon flag-usa"></span>
                                            <p>+1 252 231 2363</p>
                                        </div>
                                        <div className="flag-icon-content">
                                            <span className="flag-icon flag-india"></span>
                                            <p>+91 83770 32324</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="contact-info box-style1"><i className="flaticon-phone-1"></i>
                                    <div className="info-text">
                                        <h6>Open Hours</h6>
                                        <div className="flag-icon-content">
                                            <span className="flag-icon flag-usa"></span>
                                            <p>Monday - Friday (9:00AM - 06:00PM EST)</p>
                                        </div>
                                        <div className="flag-icon-content">
                                            <span className="flag-icon flag-india"></span>
                                            <p>Monday - Friday (9:00AM - 06:00PM IST)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </section>
            <div className="no-padding">
                <div className="map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14012.685379328217!2d77.3263402!3d28.5946361!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x97ba79d7145714a8!2sSoftogrid%20Solutions%20Private%20Limited!5e0!3m2!1sen!2sin!4v1641917150557!5m2!1sen!2sin" height="500" style={{border:'0'}} title="Softogrid Solutions" allowFullScreen="" tabIndex="0" aria-hidden="false" loading="lazy"></iframe>
                </div>
            </div>
        </>
    )
}

export default Contact
