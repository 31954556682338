import React from 'react'
import { Link } from 'react-router-dom';
import './style.scss';

export default function Footer() {
    return (
        <footer className={ 'footer site-footer footer-v1' }>
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                        <div className="widget-footer">
                            <h2 className="text-white">Useful Links</h2>
                            <ul className="list-items">
                                <li className="list-item">
                                    <Link to="/">Home</Link>
                                </li>
                                <li className="list-item">
                                    <Link to="/about-us">About Us</Link>
                                </li>
                                <li className="list-item">
                                    <Link to="/services">Services</Link>
                                </li>
                                <li className="list-item">
                                    <Link to="/contact">Contact</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                        <div className="widget-footer">
                            <h2 className="text-white">Our Services</h2>
                            <ul className="list-items">
                                <li className="list-item">
                                    <Link to="/web-development">Web Development</Link>
                                </li>
                                <li className="list-item">
                                    <Link to="/api-development">API Development</Link>
                                </li>
                                <li className="list-item">
                                    <Link to="/design">UI/UX Design</Link>
                                </li>
                                <li className="list-item">
                                    <Link to="/services">Product Management</Link>
                                </li>
                                <li className="list-item">
                                    <Link to="/services">Marketing</Link>
                                </li>
                                <li className="lisFt-item">
                                    <Link to="/services">Graphic Design</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                        <div className="widget-footer">
                            <h2 className="text-white">Email: </h2>
                            <p>Business Queries : info@softogrid.com</p>
                            <p>Want to apply : apply@softogrid.com</p>
                            <p>HR Queries : hr@softogrid.com</p>
                            <br/>
                            <h2 className="text-white"> Contact No: </h2>
                            <p> India: +91 83770 32324 </p>
                            <p> USA: +1 252 231 2363 </p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                        <div className="widget-footer">
                            <h2 className="text-white">Address</h2>
                            <div className="flag-icon-content">
                                <span className="flag-icon flag-india"></span>
                                <p>C25, C Block, Sector 8, Noida, Uttar Pradesh 201301</p>
                            </div>
                            <div className="flag-icon-content">
                                <span className="flag-icon flag-usa"></span>
                                <p>6203 San Ignacio Avenue. San Jose, CA 95119, USA</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-65">
                    <div className="copyright text-center align-self-center">
                        <p> © Copyright <strong style={{fontWeight: 400}} className="text-white">Softogrid Solutions</strong>. All Rights Reserved.</p>
                    </div>
                    <div className="credits text-center align-self-center">
                        Designed by <Link to="/" alt="SOFTOGRID SOLUTIONS PRIVATE LIMITED">SOFTOGRID SOLUTIONS PRIVATE LIMITED</Link>
                    </div>
                </div>
            </div>
        </footer>
    )
}
