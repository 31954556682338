import React from 'react'
import './style.scss';

export default function Service() {
    return (
        <section className="bg-dark-primary">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <div className="ot-heading">
                            <h1 className="text-primary-light"> Why Choose Us</h1>
                            <h3 className="main-heading text-white">We Provide Many Services Such As</h3>
                        </div>
                        <div className="space-20"></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                        <div className="serv-box-2 s2">
                            <div className="icon-main">
                                <span className="flaticon-tablet"></span>
                            </div>
                        <div className="content-box">
                            <h4>Web Development</h4>
                            <div>The first and most effective part of web development is developing a website. An attractive web design, cogent content development, effective client liaison, skilled scripting knowledge, web servers and network security configuration together add up to the building of a valuable website...
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                    <div className="serv-box-2 s2">
                        <div className="icon-main">
                            <span className="flaticon-ui"></span>
                        </div>
                        <div className="content-box">
                            <h4>App Development</h4>
                            <div>We provide application development services across the entire Software Development Life Cycle (SDLC). Through our application development and systems integration services, you can gain consulting and delivery expertise in both end-to-end systems integration and custom application development. Softogrid Solutions can custom-build to your specifications – efficiently and deliver it timely. Our services can help you drive innovation and expand into new markets while reducing overall costs.</div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                    <div className="serv-box-2 s2">
                        <div className="icon-main">
                            <span className="flaticon-report"></span>
                        </div>
                        <div className="content-box">
                            <h4>Product Management</h4>
                            <div>Product management is an organisational function within a company dealing with new product development, business justification, planning, verification, forecasting, pricing, product launch, and marketing of a product or products at all stages of the product lifecycle...</div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                    <div className="serv-box-2 s2">
                        <div className="icon-main">
                            <span className="flaticon-shield"></span>
                        </div>
                        <div className="content-box">
                            <h4>Consulting Services</h4>
                            <div>Softogrid Solutions with its service offerings developed over years of industry experience is uniquely positioned to provide scalable IT Consulting/Staffing Solutions tuned specifically to meet customer’s resources needs. At Softogrid Solutions, we provide IT consultants on contract, contract-to-hire or permanent basis to our customers’ immediate and long-term resource needs...</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}
