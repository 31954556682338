import React from 'react'
import { Technology } from '../../components/section/technology';
import { Database, Magento, TechnologyIndex, WebDevelopment } from '../../components/section/technology/technology';

const webdev = [
    {
        title : "HTML"
    },
    {
        title : "CSS"
    },
    {
        title : "ANGULAR"
    },
    {
        title : "BOOTSTRAP"
    },
    {
        title : "REACT"
    }
];

const appdev = [
    {
        title : "Android"
    },
    {
        title : "iOS"
    },
    {
        title : "React Native"
    },
];

function Technologies() {
    return (
        <>
            <WebDevelopment key="web" title="Web Development" description="We have built various complex web applications using HTML, CSS and latest JavaScript frameworks like AngularJS, ReactJS and many more." list={webdev}/>
            <section className="service-type section--misc section-T60 about-counter" key="service-type">
                <div className="container">
                    <div className="brick-wrap">
                        <div className="svg-block">
                            <h2>Cloud Services</h2>
                        </div>
                        <p><strong>Amazon Web Services (AWS)</strong> Amazon web services arrived in the marketplace in 2006 offering an incredible utility of virtual system clusters to be used by organizations as well as individuals. Code flies has a dedicated team of AWS experts who are proficient in managing client requirements with results at par the quality standards</p>
                        <p><strong>Windows Azure</strong> or Windows Azure since its release in 2010 has exhibited its immeasurable potential in building and testing applications to deploying and managing them. Codeflies’s Azure professionals are leveraging the features of IaaS, PaaS, and SaaS and various other frameworks and tools with finesse.</p>
                    </div>
                    <div className="space-60"></div>
                    <div className="brick-wrap">
                        <div className="svg-block">
                            <h2>Backend</h2>
                        </div>
                        <p>Backend is a technical term that is used by computer professionals and coders. Basically, the backend is the programmer’s code that deals with server-side operations, including CRUD functions with database and all server logic. The majority of operating syntax and data is backlogged and obtained in the backend of any application. Users can’t access the backend of any computer application.</p>
                    </div>
                    <div className="space-60"></div>
                </div>
            </section>
            <Technology />
            <TechnologyIndex />
            <Database />
            <WebDevelopment key="mobile" title="Mobile Application Development" description="" list={appdev} side="right" />
            <Magento />
        </>
    )
}

export default Technologies;
