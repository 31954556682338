import React, {useState} from 'react'

function ContactForm() {
    const [form, setform] = useState({
        name:'',
        email:'',
        message:''
    });
    const onChange = (event, key) => {
        if( event.target ) {
            setform( { ...form, [key]:event.target.value } );
        }
    }
    const onSubmit = () => {
        if( form.name && form.email && form.message ) {
            alert("Query Submitted Successfully.\nOur sales team will contact you soon!")
            setform( {name:'',email:'',message:''} );
        }
    }
    return (
        <form className="wpcf7" onSubmit={onSubmit}>
            <div className="main-form">
                <h2>Ready to Get Started?</h2>
                <p className="font14">Your email address will not be published. Required fields are marked *</p>
                <p>
                    <input type="text" name="name" value={form.name} size="40" aria-required="true" aria-invalid="false" placeholder="* Your Name" required onChange={ e => { onChange(e, 'name') } } />
                </p>
                <p>
                    <input type="email" name="email" value={form.email} size="40" aria-required="true" aria-invalid="false" placeholder="* Your Email" required onChange={ e => { onChange(e, 'email') } } />
                </p>
                <p>
                    <textarea name="message" cols="40" rows="10" aria-invalid="false" placeholder="* Message..." required onChange={ e => { onChange(e, 'message') } } value={form.message} />
                </p>
                <button className="octf-btn octf-btn-light">Send Message</button>
            </div>
        </form>
    )
}

export default ContactForm
